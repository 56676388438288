* {
    /* font-family: "Ubuntu", Helvetica, sans-serif; */
    font-family: "Noto Sans", Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

.noto-font {
    font-family: "Noto Sans", Helvetica, sans-serif;
}

.fw-thin {
    font-weight: 100 !important;
}

.section-next-link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    border: 1px solid #70707052;
    border-radius: 5px;
    padding: 10px 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.section-next-link:hover {
    color: rgba(255, 255, 255, 0.8);
    border-color: rgba(255, 255, 255, 0.8);
}

.section-next-link i {
    padding-left: 10px;
}

/* .bg-black p,
.bg-black a {
    color: var(--bs-white);
} */

.bg-light p,
.bg-light a,
.text-bg-light p {
    color: var(--bs-gray-800) !important;
}


.bg-design-br {
    /* background-image: url( /public/images/bg-lines-br.png); */
    background-image: url(../../public/images/bg-lines-br.png);
    background-size: cover;
    background-position: bottom;
    color: #343447;
}

.end-5 {
    right: 5px !important;
}

.end-10 {
    right: 10px !important;
}

.end-15 {
    right: 15px !important;
}

.end-20 {
    right: 20px !important;
}

.end-25 {
    right: 25px !important;
}

.end-30 {
    right: 30px !important;
}

.top-5 {
    top: 5px !important;
}

.top-10 {
    top: 10px !important;
}

.top-15 {
    top: 15px !important;
}

.top-20 {
    top: 20px !important;
}

.top-25 {
    top: 25px !important;
}

.top-30 {
    top: 30px !important;
}

/* company logo css */
@media (max-width: 768px) {
    #company-logo {
        /* left: 0; */
        width: 50% !important;
    }
}

/* module page css */
img.nvrt-logo-side {
    max-width: 300px;
    margin-bottom: 2rem;
}