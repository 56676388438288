/* <style type="text/css" data-fela-rehydration="358" data-fela-type="STATIC"> */
/* html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%
}

*,
*:before,
*:after {
    box-sizing: inherit
}

body {
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.8);
    position: relative;
    min-height: 100vh
} */

/* Dropdown Arrow remove */
.hide-dropdown::after {
    display: none !important;
}


._thumbnail-image {
    vertical-align: middle;
    width: 200px;
    aspect-ratio: auto 200 / 134;
    height: 134px;
    object-fit: cover;
    object-position: top;
}
.elleps-text {
    width: 10em !important; /* the element needs a fixed width (in px, em, %, etc) */
    overflow: hidden !important; /* make sure it hides the content that overflows */
    white-space: nowrap !important; /* don't break the line */
    text-overflow: ellipsis !important; /* give the beautiful '...' effect */
  }
@media screen and (max-width: 728px) {
    ._thumbnail-image {
        width: 150px;
        aspect-ratio: auto 150 / 100;
        height: 100px;
    }
}

@media screen and (max-width: 551px) {
    ._thumbnail-image {
        width: 100px;
        aspect-ratio: auto 100 / 100;
        height: 100px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd,
ol,
ul,
menu,
figure,
blockquote,
p,
pre,
form {
    margin: 0
}

menu,
ol,
ul {
    padding: 0;
    list-style: none;
    list-style-image: none
}

main {
    display: block
}

a {
    color: inherit;
    text-decoration: none
}

a,
button,
input {
    -webkit-tap-highlight-color: transparent
}

img,
svg {
    vertical-align: middle
}

button {
    background: transparent;
    overflow: visible
}

button,
input,
optgroup,
select,
textarea {
    margin: 0
}

:root {
    --reach-tabs: 1;
    --reach-menu-button: 1
}

#speechify-root {
    font-family: Sohne, sans-serif
}

div[data-popper-reference-hidden="true"] {
    visibility: hidden;
    pointer-events: none
}

/*XCode style (c) Angel Garcia <angelgarcia._mail@gmail._com>*/
._hljs {
    background: #fff;
    color: black;
}

/* Gray DOCTYPE selectors like WebKit */

._xml ._hljs-meta {
    color: #e10202;
}

._hljs-comment,
._hljs-quote {
    color: #007400;
}

._hljs-tag,
._hljs-attribute,
._hljs-keyword,
._hljs-selector-tag,
._hljs-literal,
._hljs-name {
    color: #aa0d91;
}

._hljs-variable,
._hljs-template-variable {
    color: #3F6E74;
}

._hljs-code,
._hljs-string,
._hljs-meta ._hljs-string {
    color: #c41a16;
}

._hljs-regexp,
._hljs-link {
    color: #0E0EFF;
}

._hljs-title,
._hljs-symbol,
._hljs-bullet,
._hljs-number {
    color: #1c00cf;
}

._hljs-section,
._hljs-meta {
    color: #643820;
}

._hljs-title._class_,
._hljs-class ._hljs-title,
._hljs-type,
._hljs-built_in,
._hljs-params {
    color: #5c2699;
}

._hljs-attr {
    color: #836C28;
}

._hljs-subst {
    color: #000;
}

._hljs-formula {
    background-color: #eee;
    font-style: italic;
}

._hljs-addition {
    background-color: #baeeba;
}

._hljs-deletion {
    background-color: #ffc8bd;
}

._hljs-selector-id,
._hljs-selector-class {
    color: #9b703f;
}

._hljs-doctag,
._hljs-strong {
    font-weight: bold;
}

._hljs-emphasis {
    font-style: italic;
}

/* </style>

<style type="text/css" data-fela-rehydration="358" data-fela-type="KEYFRAME"> */
@-webkit-keyframes k1 {
    0% {
        opacity: 0.8
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 0._8
    }
}

@-moz-keyframes k1 {
    0% {
        opacity: 0.8
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 0.8
    }
}

@keyframes k1 {
    0% {
        opacity: 0.8
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 0.8
    }
}

/* </style>
<style type="text/css" data-fela-rehydration="358" data-fela-type="RULE"> */
._a {
    font-family: medium-content-sans-serif-font, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif
}

._b {
    font-weight: 400
}

._c {
    background-color: rgb(0, 0, 0)
}

._l {
    height: 100vh
}

._m {
    width: 100vw
}

._n {
    display: flex
}

._o {
    align-items: center
}

._p {
    justify-content: center
}

._q {
    height: 25px
}

._r {
    fill: rgba(41, 41, 41, 1)
}

._s {
    display: grid
}

._t {
    grid-template-rows: 1fr
}

._ag {
    border-bottom: 1px solid #242424
}

._ah {
    display: block
}

._ai {
    background: #000000;
}

._aj {
    z-index: 10
}

._ak {
    background-color: #000000;
}

._al {
    width: 100%
}

._am {
    border-bottom: solid 1px #242424
}

._an {
    position: fixed
}

._ao {
    top: 0
}

._ap {
    left: 0
}

._aq {
    right: 0
}

._ay {
    max-width: 1192px
}

._az {
    min-width: 0
}

._ba {
    padding: 25px 0
}

._bb {
    height: 75px
}

._bc {
    flex-direction: row
}

._bd {
    color: inherit
}

._be {
    fill: inherit
}

._bf {
    font-size: inherit
}

._bg {
    border: inherit
}

._bh {
    font-family: inherit
}

._bi {
    letter-spacing: inherit
}

._bj {
    font-weight: inherit
}

._bk {
    padding: 0
}

._bl {
    margin: 0
}

._bm {
    cursor: pointer
}

._bn:disabled {
    cursor: not-allowed
}

._bo:disabled {
    color: #6B6B6B
}

._bp:disabled {
    fill: #6B6B6B
}

._bs {
    fill: rgba(0, 0, 0, 1)
}

._bt {
    flex: 1 0 auto
}

._bx {
    margin-right: 25px
}

._by {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif
}

._bz {
    font-size: 14px
}

._ca {
    line-height: 20px
}

._cb {
    color: #ffffff
}

._cd {
    color: rgba(255, 255, 255, 1)
}

._ce {
    padding: 2px 10px
}

._cf {
    fill: rgba(255, 255, 255, 1)
}

._cg {
    background: rgba(25, 25, 25, 1)
}

._ch {
    border-color: rgba(25, 25, 25, 1)
}

._cl:disabled {
    cursor: inherit !important
}

._cm:disabled {
    opacity: 0.1
}

._cn:disabled:hover {
    background: rgba(25, 25, 25, 1)
}

._co:disabled:hover {
    border-color: rgba(25, 25, 25, 1)
}

._cp {
    border-radius: 99em
}

._cq {
    border-width: 1px
}

._cr {
    border-style: solid
}

._cs {
    box-sizing: border-box
}

._ct {
    display: inline-block
}

._cu {
    text-decoration: none
}

._cv {
    text-align: center
}

._cw {
    height: 100%
}

._cx {
    flex-direction: column
}

._cy {
    z-index: 2
}

._do button {
    font-size: 20px
}

._dp {
    overflow: hidden
}

._dq {
    position: relative
}

._dr {
    margin-bottom: 35px
}

._ds {
    font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif
}

._ei {
    font-feature-settings: 'pnum' on, 'lnum' on;
}

._ej {
    color: #000000
}

._ek {
    margin-bottom: 50px
}

._eq h3 {
    font-size: 24px
}

._er {
    font-size: 18px
}

._es {
    line-height: 24px
}

._et {
    font-size: 16px
}

._eu {
    padding: 8px 20px
}

._ev {
    width: 213px
}

._ew {
    border-top: 1px solid #424264
}

._ex {
    animation: k1 1._2s ease-in-out infinite
}

._ey {
    padding: 32px
}

._ez {
    margin-bottom: 32px
}

._fa {
    border-radius: 10px
}

._fb {
    margin-right: 20px
}

._fc {
    height: 22px
}

._fd {
    width: 22px
}

._fe {
    background-color: #F2F2F2
}

._ff {
    height: 10px
}

._fg {
    width: 165px
}

._fh {
    align-items: flex-start
}

._fi {
    flex-wrap: wrap
}

._fy {
    font-weight: 500
}

._fz {
    font-size: 32px
}

._ga {
    line-height: 38px
}

._gb {
    letter-spacing: -0.014em
}

._gc {
    color: #F2F2F2
}

._gx {
    flex-grow: 0
}

._gy {
    margin-bottom: 24px
}

._gz {
    margin-right: 16px
}

._ha {
    top: -10px
}

._hb {
    min-width: 300px
}

._hc {
    margin-bottom: 20px
}

._hd {
    border-radius: 4px
}

._he {
    margin-right: 8px
}

._hf {
    height: 20px
}

._hg {
    width: 20px
}

._hh {
    height: 8px
}

._hi {
    width: 82px
}

._hj {
    height: 16px
}

._hk {
    margin-bottom: 12px
}

._hl {
    width: 64px
}

._ic {
    padding-bottom: 16px
}

._id {
    letter-spacing: 0
}

._ie {
    border-radius: 100px
}

._if {
    margin-bottom: 10px
}

._ig {
    height: 40px
}

._ih {
    width: 100px
}

._ii {
    flex: 0 0 auto
}

._ij {
    width: 80px
}

._ik {
    margin-top: 14px
}

._il {
    color: #437aff
}

._ip {
    position: sticky
}

._iq {
    top: 107px
}

._it {
    padding: 24px 0
}

._iu {
    margin-bottom: 8px
}

._iv {
    margin-right: 26px
}

._iw {
    color: #d1cfcf
}

._ix {
    padding: 30px 0
}

._iy {
    margin-bottom: 0
}

._iz {
    min-width: 100vw
}

._ja {
    position: static
}

._jb {
    bottom: 0
}

._jc {
    background-color: rgba(0, 0, 0, 1)
}

._je:disabled {
    color: rgba(255, 255, 255, 0.6)
}

._jf:disabled {
    fill: rgba(255, 255, 255, 0.6)
}

._ji {
    margin-top: 20px
}

._jj {
    font-size: 13px
}

._jk {
    color: rgba(255, 255, 255, 0.95)
}

._jm {
    border: 0
}

._jn {
    height: 1px
}

._jo {
    background-color: rgba(255, 255, 255, 0.95)
}

._jp {
    margin: 28px 0 20px
}

._jq {
    border-top: none
}

._jr {
    padding-top: 40px
}

._js {
    margin-bottom: 16px
}

._jt {
    margin-right: 10px
}

._ju {
    width: 40px
}

._jv {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05)
}

._jw {
    border-radius: 50%
}

._jx {
    position: absolute
}

._jy {
    border: none
}

._ka {
    margin-left: 8px
}

._kb {
    line-height: 16px
}

._kc {
    max-height: 16px
}

._kd {
    text-overflow: ellipsis
}

._ke {
    display: -webkit-box
}

._kf {
    -webkit-line-clamp: 1
}

._kg {
    -webkit-box-orient: vertical
}

._kh {
    word-break: break-all
}

._kj {
    padding-right: 2px
}

._kk {
    font-weight: 700
}

._kl {
    max-height: 40px
}

._km {
    -webkit-line-clamp: 2
}

._kn {
    padding-left: 6px
}

._ko {
    padding-right: 6px
}

._kp {
    margin-right: 3px
}

._kq {
    cursor: initial
}

._kr {
    border: 1px solid #F2F2F2
}

._ks {
    transition: background 300ms ease
}

._ku {
    white-space: nowrap
}

._kv {
    padding-bottom: 12px
}

._kw {
    padding-top: 12px
}

._kx {
    opacity: 1
}

._ky {
    max-height: auto
}

._le {
    justify-content: space-between
}

._lf {
    flex: 1 1 auto
}

._lk {
    padding-bottom: 8px
}

._mf {
    padding-top: 4px
}

._ml {
    align-items: baseline
}

._mm {
    top: -1px
}

._mn {
    padding: 0 0 0 4px
}

._mo {
    box-sizing: content-box
}

._mr {
    height: 130px
}

._ms {
    width: 290px
}

._bq:hover:not(:disabled) {
    color: rgba(25, 25, 25, 1)
}

._br:hover:not(:disabled) {
    fill: rgba(25, 25, 25, 1)
}

._ci:hover {
    background: #000000
}

._cj:hover {
    border-color: #242424
}

._ck:hover {
    cursor: pointer
}

._jg:hover:not(:disabled) {
    color: rgba(255, 255, 255, 1)
}

._jh:hover:not(:disabled) {
    fill: rgba(255, 255, 255, 0.9)
}

._jl:hover {
    text-decoration: underline
}

._jz:hover {
    background-color: none
}

._kt:hover {
    background-color: #F2F2F2
}

._mq:hover {
    color: #000000
}
@media screen and (min-width:1080px) {
    ._d {
        display: none
    }

    ._x {
        grid-template-columns: repeat(8, 1fr) repeat(4, minmax(48px, 1fr))
    }

    ._ab {
        grid-column: 1 / span 7
    }

    ._ac {
        grid-row-start: 1
    }

    ._af {
        grid-column: 9 / span 4
    }

    ._ax {
        margin: 0 64px
    }

    ._bw {
        display: inline-block
    }

    ._dl {
        width: 550px
    }

    ._dm {
        padding-bottom: 92px
    }

    ._dn {
        padding-top: 93px
    }

    ._ef {
        font-size: 106px
    }

    ._eg {
        line-height: 95px
    }

    ._eh {
        letter-spacing: -0.05em
    }

    ._ep {
        width: 80%
    }

    ._fv {
        width: calc(100% + 32px)
    }

    ._fw {
        margin-left: -16px
    }

    ._fx {
        margin-right: -16px
    }

    ._gt {
        padding-left: 16px
    }

    ._gu {
        padding-right: 16px
    }

    ._gv {
        flex-basis: 33.333333%
    }

    ._gw {
        max-width: 33.333333%
    }

    ._ia {
        padding-bottom: 32px
    }

    ._ib {
        border-bottom: 1px solid #424264
    }

    ._io {
        padding-top: 56px
    }

    ._is {
        display: block
    }

    ._ld {
        margin-bottom: 48px
    }

    ._mb {
        font-size: 20px
    }

    ._mc {
        line-height: 24px
    }

    ._md {
        max-height: 48px
    }

    ._me {
        letter-spacing: 0
    }

    ._mk {
        padding-top: 8px
    }
}

@media screen and (max-width:1079.98px) {

    ._e {
        display: none
    }

    ._bv {
        display: inline-block
    }

    ._in {
        padding-top: 56px
    }
}

@media screen and (max-width:903.98px) {

    ._f {
        display: none
    }

    ._u {
        grid-template-columns: 1fr
    }

    ._bu {
        display: inline-block
    }

    ._ho {
        border-top: 1px solid #424264
    }

    ._im {
        padding-top: 40px
    }
}

/* </style>
<style type="text/css" data-fela-rehydration="358" data-fela-type="RULE" media="all and (max-width: 727._98px)"> */

/* max-width 727._98px */
@media screen and (max-width:727.98px) {

    ._g {
        display: none
    }

    ._cc {
        display: inline-block
    }

    ._hn {
        border-top: 1px solid #424264
    }

    ._jd {
        padding: 24px 0
    }

    ._lg {
        padding-right: 0px
    }

    ._lh {
        margin-right: 0px
    }

    ._li {
        display: flex
    }

    ._lj {
        align-items: flex-start
    }

    ._mt {
        height: 0px
    }

    ._mu {
        width: 0px
    }
}
@media screen and (max-width:551.98px) {

    ._h {
        display: none
    }

    ._at {
        margin: 0 24px
    }

    ._cz {
        width: 100%
    }

    ._da {
        padding-bottom: 92px
    }

    ._db {
        padding-top: 93px
    }

    ._dt {
        font-size: 70px
    }

    ._du {
        line-height: 74px
    }

    ._dv {
        letter-spacing: -0.05em
    }

    ._fj {
        width: calc(100% + 24px)
    }

    ._fk {
        margin-left: -12px
    }

    ._fl {
        margin-right: -12px
    }

    ._gd {
        padding-left: 12px
    }

    ._ge {
        padding-right: 12px
    }

    ._gf {
        flex-basis: 100%
    }

    ._gg {
        max-width: 100%
    }

    ._hm {
        border-top: 1px solid #424264
    }

    ._hp {
        display: block
    }

    ._hs {
        padding-bottom: 24px
    }

    ._ht {
        padding-top: 24px
    }

    ._kz {
        margin-bottom: 32px
    }

    ._ll {
        font-size: 16px
    }

    ._lm {
        line-height: 20px
    }

    ._ln {
        max-height: 40px
    }

    ._lo {
        letter-spacing: 0
    }

    ._mg {
        padding-top: 4px
    }

    ._mp {
        margin-right: 4px
    }
}

@media screen and (min-width:904px) and (max-width:1079.98px) {

    ._i {
        display: none
    }

    ._v {
        grid-template-columns: repeat(8, 1fr) repeat(4, minmax(48px, 1fr))
    }

    ._w {
        grid-column-gap: 32px
    }

    ._y {
        grid-column: 1 / span 8
    }

    ._z {
        grid-row-start: 1
    }

    ._ae {
        grid-column: 9 / span 4
    }

    ._aw {
        margin: 0 64px
    }

    ._di {
        width: 550px
    }

    ._dj {
        padding-bottom: 92px
    }

    ._dk {
        padding-top: 93px
    }

    ._ec {
        font-size: 106px
    }

    ._ed {
        line-height: 95px
    }

    ._ee {
        letter-spacing: -0.05em
    }

    ._eo {
        width: 80%
    }

    ._fs {
        width: calc(100% + 32px)
    }

    ._ft {
        margin-left: -16px
    }

    ._fu {
        margin-right: -16px
    }

    ._gp {
        padding-left: 16px
    }

    ._gq {
        padding-right: 16px
    }

    ._gr {
        flex-basis: 50%
    }

    ._gs {
        max-width: 50%
    }

    ._hy {
        padding-bottom: 32px
    }

    ._hz {
        border-bottom: 1px solid #424264
    }

    ._ir {
        display: block
    }

    ._lc {
        margin-bottom: 48px
    }

    ._lx {
        font-size: 20px
    }

    ._ly {
        line-height: 24px
    }

    ._lz {
        max-height: 48px
    }

    ._ma {
        letter-spacing: 0
    }

    ._mj {
        padding-top: 8px
    }
}

/* </style>

<style type="text/css" data-fela-rehydration="358" data-fela-type="RULE"
media="all and (min-width: 728px) and (max-width: 903._98px)"> */

/* min-width 728px and max-width 903._98px */
@media screen and (min-width:728px) and (max-width:903.98px) {

    ._j {
        display: none
    }

    ._av {
        margin: 0 48px
    }

    ._df {
        width: 500px
    }

    ._dg {
        padding-bottom: 92px
    }

    ._dh {
        padding-top: 93px
    }

    ._dz {
        font-size: 85px
    }

    ._ea {
        line-height: 88px
    }

    ._eb {
        letter-spacing: -0.055em
    }

    ._em {
        width: 80%
    }

    ._fp {
        width: calc(100% + 28px)
    }

    ._fq {
        margin-left: -14px
    }

    ._fr {
        margin-right: -14px
    }

    ._gl {
        padding-left: 14px
    }

    ._gm {
        padding-right: 14px
    }

    ._gn {
        flex-basis: 50%
    }

    ._go {
        max-width: 50%
    }

    ._hr {
        display: block
    }

    ._hw {
        padding-bottom: 24px
    }

    ._hx {
        padding-top: 24px
    }

    ._lb {
        margin-bottom: 48px
    }

    ._lt {
        font-size: 20px
    }

    ._lu {
        line-height: 24px
    }

    ._lv {
        max-height: 48px
    }

    ._lw {
        letter-spacing: 0
    }

    ._mi {
        padding-top: 8px
    }
}
@media screen and (min-width:552px) and (max-width:727.98px) {

    ._k {
        display: none
    }

    ._au {
        margin: 0 24px
    }

    ._dc {
        width: 500px
    }

    ._dd {
        padding-bottom: 92px
    }

    ._de {
        padding-top: 93px
    }

    ._dw {
        font-size: 85px
    }

    ._dx {
        line-height: 88px
    }

    ._dy {
        letter-spacing: -0.055em
    }

    ._el {
        width: 80%
    }

    ._fm {
        width: calc(100% + 24px)
    }

    ._fn {
        margin-left: -12px
    }

    ._fo {
        margin-right: -12px
    }

    ._gh {
        padding-left: 12px
    }

    ._gi {
        padding-right: 12px
    }

    ._gj {
        flex-basis: 100%
    }

    ._gk {
        max-width: 100%
    }

    ._hq {
        display: block
    }

    ._hu {
        padding-bottom: 24px
    }

    ._hv {
        padding-top: 24px
    }

    ._la {
        margin-bottom: 48px
    }

    ._lp {
        font-size: 16px
    }

    ._lq {
        line-height: 20px
    }

    ._lr {
        max-height: 40px
    }

    ._ls {
        letter-spacing: 0
    }

    ._mh {
        padding-top: 4px
    }
}


@media (prefers-reduced-motion: no-preference) {

    ._ar {
        transition: background-color 300ms linear
    }

    ._as button {
        transition: background-color 300ms linear, color 300ms linear
    }
}

@media screen and (orientation: landscape) and (max-width:903.98px) {

    ._ki {
        max-height: none
    }
}
