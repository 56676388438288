.fof-section{
    padding: 4rem 2rem;
    background-color: rgb(255, 255, 255);
    display: block;
     flex-wrap: wrap;
     text-align: center;
     height: 100vh;
     align-items: center;
     justify-content: center;
  }

  
  .fof-section .fof-error{
    font-size: 150px;
    color: black;
    text-shadow: 
      1px 1px 1px rgba(114, 114, 114, 0.318),    
      2px 2px 1px rgba(114, 114, 114, 0.318),
      3px 3px 1px rgba(114, 114, 114, 0.318),
      4px 4px 1px rgba(114, 114, 114, 0.318),
      5px 5px 1px rgba(114, 114, 114, 0.318),
      6px 6px 1px rgba(114, 114, 114, 0.318),
      7px 7px 1px rgba(114, 114, 114, 0.318),
      8px 8px 1px rgba(114, 114, 114, 0.318),
      25px 25px 8px rgba(0,0,0, 0.2);
  }
  
  .fof-page{
    margin: 2rem 0;
    font-size: 20px;
    font-weight: 600;
    color: #444;
  }
  
  .fof-back-home{
    display: inline-block;
    border: 2px solid #222;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.75rem 1rem 0.6rem;
    transition: all 0.2s linear;
    box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
    background: #222;
    border-radius: 6px;
  }
  .fof-back-home:hover{
    background: #3f3e3e;
    color: #f8f8f8;
  }