/* marquee in mobile view */
@media (max-width: 768px) {
  .marquee {
    overflow: hidden;
  }
}

.marquee-content {
  display: flex;
  animation: scrolling 30s linear infinite;
}

.marquee-item {
  flex: 0 0 16vw;
  margin: 0 5vw;
  /* flex: 0 0 20vw; */
  /* margin: 0 2vw; */
  /* max-width: 10vw; */
}

.marquee-item img {
  display: block;
  /* width: 100%; */
  /*   padding: 0 20px; */
}

/*
 * Globals
 */


/* Custom default button */
.btn-light,
.btn-light:hover,
.btn-light:focus {
  color: #333;
  text-shadow: none;
  /* Prevent inheritance from `body` */
}

.cover-container {
  max-width: 50em;
  text-align: center;
  height: calc(100vh);
}


@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translatex(-144vw);
  }
}