.btn-group {

    .select {
        position: relative;

        input:checked+label {
            color: black;
            background-color: #feffff;

            &:hover,
            &:focus,
            &:active {
                background-color: #fbf8f7;
            }
        }

        input {
            opacity: 0;
            position: absolute;
        }

        .button_select {
            margin: 0 10px 10px 0;
            display: flex;
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }

}


.option {
    position: relative;

    input {
        opacity: 0;
        position: absolute;
    }

    input:checked+span {
        background-color: #ffc107;

        &:hover,
        &:focus,
        &:active {
            background-color: #ffc107;
        }
    }

    .btn-option {
        margin: 0 10px 10px 0;
        display: flex;
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }
    }
}

/* #list-container:{

} */
@media (max-width: 520px) {
    #list-container {
        display: block !important;
    }
}