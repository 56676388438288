
.card .background-image {
    background-color: black;
    background-size: 200px;
    background-position: 100px 10px;
    background-repeat: no-repeat;
    opacity: 0.1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.card.text-bg-dark:hover{
    cursor: pointer;
}

.card.text-bg-dark:hover .background-image {
    opacity: 0.02;
}

.card.text-bg-dark.default-bg:hover {
    background-color: var(--bs-orange) !important;
}
.card.text-bg-dark.bg-unity:hover {
    background-color: var(--bs-primary) !important;
}

.card.text-bg-dark.bg-unreal-engine:hover {
    background-color: var(--bs-orange) !important;
}

.card.text-bg-dark.bg-ui-ux:hover {
    background-color: var(--bs-indigo) !important;
}

.card.text-bg-dark.bg-ai-ml:hover {
    background-color: var(--bs-teal) !important;
}

.card.text-bg-dark.bg-autodesk:hover {
    background-color: var(--bs-cyan) !important;
}

.card.text-bg-dark.bg-full-stack:hover {
    background-color: var(--bs-yellow) !important;
}

.arrow-icon {
    display: none;
    background-image: url(../images/straight-right-arrow.png);
    background-size: contain;
    background-position: 50px;
    background-repeat: no-repeat;
    position: absolute;
    width: 256px;
    height: 40%;
    z-index: 1;
    animation: direction 0.5s none;
    animation-fill-mode: forwards;
}

@keyframes direction {
  0%   {left: -100px; top: 0px;}
  100% {left: 0px; top: 0px;}
}

.card.text-bg-dark:hover .arrow-icon {
    display: block;
}

.z-index-1 {
    z-index: 1;
}

.z-index-0 {
    z-index: 0;
}

.card:hover .aura-icon {
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.2) -0.2rem 0.2rem 4px); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: drop-shadow(rgba(0, 0, 0, 0.2) -0.2rem 0.2rem 4px); /* Microsoft Edge and Firefox 35+ */
}

.aura-icon {
    /* background-image: url(../public/images/aura-logo-icon.png); */
    background-size: cover;
    width: 32px;
    height: 34px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position-y: 0;
    bottom: -3px;
    position: relative;
}


.career-page .background-image img {
    width: 35%;
    display: block;
    float: right;
    margin-right: 4rem;
    margin-top: 2rem;
}