.title {
    margin: 0;
    font-size: 1.5rem;
    color: #121926;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
    margin-bottom: 0.35em;
    color: #2196f3;
}

.paragraph {
    margin: 0;
    font-size: 0.75rem;
    color: #697586;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 1.66;
    font-size: 16px;
    text-align: inherit;
}

.form-email-field {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4375em;
    font-family: 'Roboto', sans-serif;
    color: #364152;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    background: #f8fafc;
    border-radius: 12px !important;
}

.form-password-field {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    background: #f8fafc;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 12px !important;
    padding-right: 14px;
}

.sign-up {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #121926;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    line-height: 1.75;
    -webkit-text-decoration: none;
    text-decoration: none;
}

#login-body {
    background-color: #eef2f6 !important;
}