/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.video-background-holder {
    position: relative;
    height: calc(100vh);
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }
  
  .video-background-holder video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    max-width: fit-content;
    max-height: fit-content;
  }
  
  .video-background-content {
    position: relative;
    z-index: 2;
  }
  
  .video-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #181821;
    opacity: 0.8;
    z-index: 1;
  }
  
  /*
  *
  * ==========================================
  * FOR DEMO PURPOSES
  * ==========================================
  *
  */
  
  code {
    padding: 0 0.15rem;
    background: #f5f5f5;
    border-radius: 0.2rem;
    color: var(--bs-code-color);
  }

  .bg-black code {
    padding: 0.15rem 0.4rem;
    background: var(--bs-gray-800);
    border-radius: 0.2rem;
  }
  


.bg-image {
  background-image: url(/public/images/vr-training-bg.webp);
  width: 100vw;
  height: 100vh;
  /* min-height: 20rem; */
  display: block;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-repeat: no-repeat;
  filter: brightness(0.15);
}