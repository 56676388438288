/* html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%
} */

/* 
*,
*:before,
*:after {
    box-sizing: inherit
}

body {
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.8);
    position: relative;
    min-height: 100vh
} */
.highlight-bg-color{
    background-color: rgba(255, 255, 3, 0.839);
    color: black;
}
#content-font {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #e2e3e5;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #c4c8cb;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33, 37, 41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33, 37, 41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: inherit;
    --bs-link-color: #0d6efd;
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-code-color: #d63384;
    --bs-highlight-color: #212529;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
    --reach-tabs: 1;
    --reach-menu-button: 1;
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-toast-width: 320px;
    --toastify-toast-offset: 16px;
    --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
    --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
    --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
    --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-toast-bd-radius: 6px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(to right,
            #4cd964,
            #5ac8fa,
            #007aff,
            #34aadc,
            #5856d6,
            #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
    --toastify-color-progress-bgo: 0.2;
    --fa-style-family-brands: "Font Awesome 6 Brands";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free";
    --fa-style-family-classic: "Font Awesome 6 Free";
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
    text-align: var(--bs-body-text-align);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: #ffffff;
    word-break: break-word;
    font-style: normal;
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    margin-bottom: -0.46em;
    font-size: 20px;
    margin-top: 2.14em;
    line-height: 32px;
    letter-spacing: -0.003em;
}

#top-navbar {
    /* position: fixed; */
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
}

#top-navbar.hidden {
    transform: translateY(-100%);
}




h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd,
ol,
ul,
menu,
figure,
blockquote,
p,
pre,
form {
    margin: 0
}

menu,
ol,
/* ul {
    padding: 0;
    list-style: none;
    list-style-image: none
} */

main {
    display: block
}

a {
    color: inherit;
    text-decoration: none
}

a,
button,
input {
    -webkit-tap-highlight-color: transparent
}

img,
svg {
    vertical-align: middle
}

button {
    background: transparent;
    overflow: visible
}

button,
input,
optgroup,
select,
textarea {
    margin: 0
}

:root {
    --reach-tabs: 1;
    --reach-menu-button: 1
}

#speechify-root {
    font-family: Sohne, sans-serif
}

div[data-popper-reference-hidden="true"] {
    visibility: hidden;
    pointer-events: none
}

.hljs {
    background: #fff;
    color: black;
}

/* Gray DOCTYPE selectors like WebKit */
.xml .hljs-meta {
    color: #c0c0c0;
}

.hljs-comment,
.hljs-quote {
    color: #007400;
}

.hljs-tag,
.hljs-attribute,
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name {
    color: #aa0d91;
}

.hljs-variable,
.hljs-template-variable {
    color: #3F6E74;
}

.hljs-code,
.hljs-string,
.hljs-meta .hljs-string {
    color: #c41a16;
}

.hljs-regexp,
.hljs-link {
    color: #0E0EFF;
}

.hljs-title,
.hljs-symbol,
.hljs-bullet,
.hljs-number {
    color: #1c00cf;
}

.hljs-section,
.hljs-meta {
    color: #643820;
}

.hljs-title.class_,
.hljs-class .hljs-title,
.hljs-type,
.hljs-built_in,
.hljs-params {
    color: #5c2699;
}

.hljs-attr {
    color: #836C28;
}

.hljs-subst {
    color: #000;
}

.hljs-formula {
    background-color: #eee;
    font-style: italic;
}

.hljs-addition {
    background-color: #baeeba;
}

.hljs-deletion {
    background-color: #ffc8bd;
}

.hljs-selector-id,
.hljs-selector-class {
    color: #9b703f;
}

.hljs-doctag,
.hljs-strong {
    font-weight: bold;
}

.hljs-emphasis {
    font-style: italic;
}

@-webkit-keyframes k1 {
    0% {
        opacity: 0.8
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 0.8
    }
}

@-moz-keyframes k1 {
    0% {
        opacity: 0.8
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 0.8
    }
}

@keyframes k1 {
    0% {
        opacity: 0.8
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 0.8
    }
}

.a {
    font-family: medium-content-sans-serif-font, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif
}

.b {
    font-weight: 400
}

.c {
    background-color: #000
}

.l {
    display: block
}

.m {
    position: sticky
}

.n {
    top: 0
}

.o {
    z-index: 500
}

.p {
    padding: 0 24px
}

.q {
    align-items: center
}

.r {
    border-bottom: solid 1px #F2F2F2
}

.y {
    height: 41px
}

.z {
    line-height: 20px
}

.ab {
    display: flex
}

.ac {
    height: 73px
}

.ae {
    flex: 1 0 auto
}

.af {
    color: inherit
}

.ag {
    fill: inherit
}

.ah {
    font-size: inherit
}

.ai {
    border: inherit
}

.aj {
    font-family: inherit
}

.ak {
    letter-spacing: inherit
}

.al {
    font-weight: inherit
}

.am {
    padding: 0
}

.an {
    margin: 0
}

.ao {
    cursor: pointer
}

.ap:disabled {
    cursor: not-allowed
}

.aq:disabled {
    color: #6B6B6B
}

.ar:disabled {
    fill: #6B6B6B
}

.au {
    fill: rgba(0, 0, 0, 1)
}

.av {
    height: 22px
}

.aw {
    margin-left: 16px
}

.ax {
    border: none
}

.ay {
    border-radius: 20px
}

.az {
    width: 240px
}

.ba {
    background: #212529
}

.bb path {
    fill: #6B6B6B
}

.bd {
    outline: none
}

.be {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif !important
}

.bf {
    font-size: 14px
}

.bg {
    width: 100%
}

.bh {
    padding: 10px 20px 10px 0
}

.bi {
    background-color: transparent
}

.bj {
    color: #ffffff
}

.bk::placeholder {
    color: #6B6B6B
}

.bl {
    display: inline-block
}

.bm {
    margin-left: 12px
}

.bn {
    margin-right: 12px
}

.bo {
    border-radius: 4px
}

.bp {
    margin-left: 24px
}

.bq {
    height: 24px
}

.bw {
    background-color: #000000
}

.bx {
    border-radius: 50%
}

.by {
    height: 32px
}

.bz {
    width: 32px
}

.ca {
    justify-content: center
}

.cg {
    max-width: 680px
}

.ch {
    min-width: 0
}

.ci {
    animation: k1 1.2s ease-in-out infinite
}

.cj {
    height: 100vh
}

.ck {
    margin-bottom: 16px
}

.cl {
    margin-top: 48px
}

.cm {
    align-items: flex-start
}

.cn {
    flex-direction: column
}

.co {
    justify-content: space-between
}

.cp {
    margin-bottom: 24px
}

.cv {
    width: 80%
}

.cw {
    background-color: #f2f2f2
}

.dc {
    height: 44px
}

.dd {
    width: 44px
}

.de {
    margin: auto 0
}

.df {
    margin-bottom: 4px
}

.dg {
    height: 16px
}

.dh {
    width: 120px
}

.di {
    width: 80px
}

.do {
    margin-bottom: 8px
}

.dp {
    width: 96%
}

.dq {
    width: 98%
}

.dr {
    width: 81%
}

.ds {
    margin-left: 8px
}

.dt {
    color: #e0e0e0
}

.du {
    font-size: 13px
}

.dv {
    height: 100%
}

.eo {
    color: #FFFFFF
}

.ep {
    fill: #FFFFFF
}

.eq {
    background: #1A8917
}

.er {
    border-color: #1A8917
}

.ev:disabled {
    cursor: inherit !important
}

.ew:disabled {
    opacity: 0.3
}

.ex:disabled:hover {
    background: #1A8917
}

.ey:disabled:hover {
    border-color: #1A8917
}

.ez {
    border-radius: 99em
}

.fa {
    border-width: 1px
}

.fb {
    border-style: solid
}

.fc {
    box-sizing: border-box
}

.fd {
    text-decoration: none
}

.fe {
    text-align: center
}

.fh {
    margin-right: 32px
}

.fi {
    position: relative
}

.fj {
    fill: #ffffff
}

.fm {
    background: transparent
}

.fn svg {
    margin-left: 4px
}

.fo svg {
    fill: #6B6B6B
}

.fq {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.fr {
    position: absolute
}

.fy {
    margin: 0 24px
}

.gc {
    background: rgba(255, 255, 255, 1)
}

.gd {
    border: 1px solid #F2F2F2
}

.ge {
    box-shadow: 0 1px 4px #F2F2F2
}

.gf {
    max-height: 100vh
}

.gg {
    overflow-y: auto
}

.gh {
    left: 0
}

.gi {
    top: calc(100vh + 100px)
}

.gj {
    bottom: calc(100vh + 100px)
}

.gk {
    width: 10px
}

.gl {
    pointer-events: none
}

.gm {
    word-break: break-word
}

.gn {
    word-wrap: break-word
}

.go:after {
    display: block
}

.gp:after {
    content: ""
}

.gq:after {
    clear: both
}

.gr {
    line-height: 1.23
}

.gs {
    letter-spacing: 0
}

.gt {
    font-style: normal
}

.gu {
    font-weight: 700
}

.hp {
    margin-bottom: -0.27em
}

.hq {
    line-height: 1.394
}

.ig {
    font-style: inherit
}

@media all and (max-width: 551.98px) {
    .ih {
        font-size: 8px;
    }
}

@media all and (min-width: 552px) and (max-width: 727.98px) {
    .ii {
        font-size: 8px;
    }
}

@media all and (min-width: 728px) and (max-width: 903.98px) {
    .ij {
        font-size: 16px;
    }
}

@media all and (min-width: 904px) and (max-width: 1079.98px) {
    .ik {
        font-size: 16px;
    }
}

@media all and (min-width: 1080px) {
    .il {
        font-size: 16px;
    }
}


.ir {
    align-items: baseline
}

.is {
    width: 48px
}

.it {
    height: 48px
}

.iu {
    border: 2px solid rgba(255, 255, 255, 1)
}

.iv {
    z-index: 0
}

.iw {
    box-shadow: none
}

.ix {
    border: 1px solid rgba(0, 0, 0, 0.05)
}

.iy {
    margin-bottom: 2px
}

.iz {
    flex-wrap: nowrap
}

.ja {
    font-size: 16px
}

.jb {
    line-height: 24px
}

.jd {
    margin: 0 8px
}

.je {
    display: inline
}

.jf {
    color: #1A8917
}

.jg {
    fill: #1A8917
}

.jj {
    flex: 0 0 auto
}

.jm {
    flex-wrap: wrap
}

.jn {
    padding-left: 8px
}

.jo {
    padding-right: 8px
}

.kp>* {
    flex-shrink: 0
}

.kq {
    overflow-x: scroll
}

.kr::-webkit-scrollbar {
    display: none
}

.ks {
    scrollbar-width: none
}

.kt {
    -ms-overflow-style: none
}

.ku {
    width: 74px
}

.kv {
    flex-direction: row
}

.kw {
    margin-right: 4px
}

.kz {
    -webkit-user-select: none
}

.la {
    border: 0
}

.lb {
    fill: rgb(255, 250, 250)
}

.le {
    outline: 0
}

.lf {
    user-select: none
}

.lg>svg {
    pointer-events: none
}

.lp {
    cursor: progress
}

.lq {
    margin-left: 4px
}

.lr {
    margin-top: 0px
}

.ls {
    opacity: 1
}

.lt {
    padding: 4px 0
}

.lw {
    width: 16px
}

.ly {
    display: inline-flex
}

.me {
    max-width: 100%
}

.mf {
    padding: 8px 2px
}

.mg svg {
    color: #ffffff
}

.mx {
    margin-left: auto
}

.my {
    margin-right: auto
}

.mz {
    max-width: 1200px
}

.nf {
    clear: both
}

.nh {
    cursor: zoom-in
}

.ni {
    z-index: auto
}

.nk {
    height: auto
}

.nl {
    margin-top: 10px
}

.nm {
    max-width: 728px
}

.np {
    line-height: 1.58
}

.nq {
    letter-spacing: -0.004em
}

.nr {
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif
}

.ok {
    margin-bottom: -0.46em
}

.ol {
    font-style: italic
}

.om {
    text-decoration: underline
}

.on {
    line-height: 1.12
}

.oo {
    letter-spacing: -0.022em
}

.op {
    font-weight: 600
}

.pi {
    margin-bottom: -0.28em
}

.po {
    list-style-type: decimal
}

.pp {
    margin-left: 30px
}

.pq {
    padding-left: 0px
}

.pw {
    list-style-type: disc
}

.px {
    max-width: 512px
}

.py {
    line-height: 1.18
}

.qm {
    margin-bottom: -0.31em
}

.qo {
    margin-top: 32px
}

.qp {
    margin-bottom: 14px
}

.qq {
    padding-top: 24px
}

.qr {
    padding-bottom: 10px
}

.qs {
    background-color: #000000
}

.qt {
    height: 3px
}

.qu {
    width: 3px
}

.qv {
    margin-right: 20px
}

.rb {
    margin-bottom: 26px
}

.rc {
    margin-top: 6px
}

.rd {
    margin-top: 8px
}

.re {
    margin-right: 8px
}

.rf {
    padding: 8px 16px
}

.rg {
    border-radius: 100px
}

.rh {
    transition: background 300ms ease
}

.rj {
    white-space: nowrap
}

.rk {
    border-top: none
}

.rq {
    height: 52px
}

.rr {
    max-height: 52px
}

.rs {
    box-sizing: content-box
}

.rt {
    position: static
}

.ru {
    z-index: 1
}

.rw {
    max-width: 155px
}

.sh {
    align-items: flex-end
}

.si {
    width: 76px
}

.sj {
    height: 76px
}

.sk {
    border: 2px solid #F9F9F9
}

.sl {
    height: 72px
}

.sm {
    width: 72px
}

.sn {
    width: auto
}

.so {
    stroke: #F2F2F2
}

.sp {
    height: 36px
}

.sq {
    width: 36px
}

.sr {
    color: #F2F2F2
}

.ss {
    fill: #F2F2F2
}

.st {
    background: #F2F2F2
}

.su {
    border-color: #F2F2F2
}

.ta {
    font-weight: 500
}

.tb {
    font-size: 24px
}

.tc {
    line-height: 30px
}

.td {
    letter-spacing: -0.016em
}

.te {
    margin-top: 16px
}

.tf {
    height: 0px
}

.tg {
    border-bottom: solid 1px #E5E5E5
}

.tm {
    margin-top: 72px
}

.tn {
    padding: 24px 0
}

.to {
    margin-bottom: 0px
}

.tp {
    margin-right: 16px
}

.as:hover:not(:disabled) {
    color: rgba(25, 25, 25, 1)
}

.at:hover:not(:disabled) {
    fill: rgba(25, 25, 25, 1)
}

.es:hover {
    background: #156D12
}

.et:hover {
    border-color: #156D12
}

.eu:hover {
    cursor: pointer
}

.fk:hover {
    color: #242424
}

.fl:hover {
    fill: #242424
}

.fp:hover svg {
    fill: #242424
}

.fs:hover {
    background-color: rgba(0, 0, 0, 0.1)
}

.jc:hover {
    text-decoration: underline
}

.jh:hover:not(:disabled) {
    color: #156D12
}

.ji:hover:not(:disabled) {
    fill: #156D12
}

.ld:hover {
    fill: #cacaca
}

.lu:hover {
    fill: #cacaca
}

.lv:hover p {
    color: #cacaca
}

.lx {
    color: #ffffff
}

.lx:hover {
    color: #cacaca
}

.mh:hover svg {
    color: #cacaca
}

.ri:hover {
    background-color: #cacaca
}

.sv:hover {
    background: #cacaca
}

.sw:hover {
    border-color: #cacaca
}

.sx:hover {
    cursor: wait
}

.sy:hover {
    color: #cacaca
}

.sz:hover {
    fill: #cacaca
}

.bc:focus-within path {
    fill: #242424
}

.lc:focus {
    fill: rgba(8, 8, 8, 1)
}

.mi:focus svg {
    color: #000000
}

.nj:focus {
    transform: scale(1.01)
}

.lh:active {
    border-style: none
}

@media screen and (min-width:1080px) {

    .d {
        display: none
    }

    .bv {
        width: 64px
    }

    .cf {
        margin: 0 64px
    }

    .cu {
        height: 48px
    }

    .db {
        margin-bottom: 52px
    }

    .dn {
        margin-bottom: 48px
    }

    .ee {
        font-size: 14px
    }

    .ef {
        line-height: 20px
    }

    .el {
        font-size: 13px
    }

    .em {
        padding: 5px 12px
    }

    .fg {
        display: flex
    }

    .fx {
        margin-bottom: 68px
    }

    .gb {
        max-width: 680px
    }

    .hl {
        font-size: 42px
    }

    .hm {
        margin-top: 1.19em
    }

    .hn {
        line-height: 52px
    }

    .ho {
        letter-spacing: -0.011em
    }

    .id {
        font-size: 22px
    }

    .ie {
        margin-top: 0.92em
    }

    .if {
        line-height: 28px
    }

    .iq {
        align-items: center
    }

    .kb {
        border-top: solid 1px #424264
    }

    .kc {
        border-bottom: solid 1px #424264
    }

    .kd {
        margin: 32px 0 0
    }

    .ke {
        padding: 3px 8px
    }

    .kn>* {
        margin-right: 24px
    }

    .ko> :last-child {
        margin-right: 0
    }

    .lo {
        margin-top: 0px
    }

    .md {
        margin: 0
    }

    .ne {
        margin-top: 56px
    }

    .og {
        font-size: 20px
    }

    .oh {
        margin-top: 2.14em
    }

    .oi {
        line-height: 32px
    }

    .oj {
        letter-spacing: -0.003em
    }

    .pe {
        font-size: 24px
    }

    .pf {
        margin-top: 1.95em
    }

    .pg {
        line-height: 30px
    }

    .ph {
        letter-spacing: -0.016em
    }

    .pn {
        margin-top: 0.94em
    }

    .pv {
        margin-top: 1.14em
    }

    .qj {
        margin-top: 1.72em
    }

    .qk {
        line-height: 24px
    }

    .ql {
        letter-spacing: 0
    }

    .ra {
        margin-top: 1.25em
    }

    .rp {
        margin-bottom: 88px
    }

    .sb {
        display: inline-block
    }

    .sg {
        padding-top: 72px
    }

    .tl {
        margin-top: 40px
    }
}

@media screen and (max-width:1079.98px) {

    .e {
        display: none
    }

    .ln {
        margin-top: 0px
    }

    .nn {
        margin-left: auto
    }

    .no {
        text-align: center
    }

    .sa {
        display: inline-block
    }
}

@media screen and (max-width:903.98px) {

    .f {
        display: none
    }

    .lm {
        margin-top: 0px
    }

    .rz {
        display: inline-block
    }
}

@media screen and (max-width:727.98px) {

    .g {
        display: none
    }

    .lk {
        margin-top: 0px
    }

    .ll {
        margin-right: 0px
    }

    .qn {
        display: block
    }

    .ry {
        display: inline-block
    }
}

@media screen and (max-width:551.98px) {
    .h {
        display: none
    }

    .s {
        display: flex
    }

    .t {
        justify-content: space-between
    }

    .br {
        width: 24px
    }

    .cb {
        margin: 0 24px
    }

    .cq {
        height: 40px
    }

    .cx {
        margin-bottom: 44px
    }

    .dj {
        margin-bottom: 32px
    }

    .dw {
        font-size: 13px
    }

    .dx {
        line-height: 20px
    }

    .eg {
        padding: 0px 8px 1px
    }

    .ft {
        margin-bottom: 4px
    }

    .gv {
        font-size: 32px
    }

    .gw {
        margin-top: 1.20em
    }

    .gx {
        line-height: 38px
    }

    .gy {
        letter-spacing: -0.014em
    }

    .hr {
        font-size: 18px
    }

    .hs {
        margin-top: 0.79em
    }

    .ht {
        line-height: 24px
    }

    .im {
        align-items: flex-start
    }

    .jk {
        flex-direction: column
    }

    .jp {
        margin: 24px -24px 0
    }

    .jq {
        padding: 0
    }

    .kf>* {
        margin-right: 8px
    }

    .kg> :last-child {
        margin-right: 24px
    }

    .kx {
        margin-left: 0px
    }

    .li {
        margin-top: 0px
    }

    .lj {
        margin-right: 0px
    }

    .lz {
        margin: 0
    }

    .mj {
        border: 1px solid #F2F2F2
    }

    .mk {
        border-radius: 99em
    }

    .ml {
        padding: 0px 16px 0px 12px
    }

    .mm {
        height: 38px
    }

    .mn {
        align-items: center
    }

    .mp svg {
        margin-right: 8px
    }

    .na {
        margin-top: 40px
    }

    .ns {
        margin-top: 1.56em
    }

    .nt {
        line-height: 28px
    }

    .nu {
        letter-spacing: -0.003em
    }

    .oq {
        font-size: 20px
    }

    .or {
        margin-top: 1.2em
    }

    .os {
        letter-spacing: 0
    }

    .pj {
        margin-top: 0.67em
    }

    .pr {
        margin-top: 1.34em
    }

    .pz {
        font-size: 16px
    }

    .qa {
        margin-top: 1.23em
    }

    .qw {
        margin-top: 0.93em
    }

    .rl {
        margin-bottom: 80px
    }

    .rx {
        display: inline-block
    }

    .sc {
        padding-top: 48px
    }

    .th {
        margin-top: 32px
    }

    .mo:hover {
        border-color: #E5E5E5
    }
}

@media screen and (min-width:904px) and (max-width:1079.98px) {
    .i {
        display: none
    }

    .bu {
        width: 64px
    }

    .ce {
        margin: 0 64px
    }

    .ct {
        height: 48px
    }

    .da {
        margin-bottom: 52px
    }

    .dm {
        margin-bottom: 48px
    }

    .ec {
        font-size: 14px
    }

    .ed {
        line-height: 20px
    }

    .ej {
        font-size: 13px
    }

    .ek {
        padding: 5px 12px
    }

    .ff {
        display: flex
    }

    .fw {
        margin-bottom: 68px
    }

    .ga {
        max-width: 680px
    }

    .hh {
        font-size: 42px
    }

    .hi {
        margin-top: 1.19em
    }

    .hj {
        line-height: 52px
    }

    .hk {
        letter-spacing: -0.011em
    }

    .ia {
        font-size: 22px
    }

    .ib {
        margin-top: 0.92em
    }

    .ic {
        line-height: 28px
    }

    .ip {
        align-items: center
    }

    .jx {
        border-top: solid 1px #F2F2F2
    }

    .jy {
        border-bottom: solid 1px #F2F2F2
    }

    .jz {
        margin: 32px 0 0
    }

    .ka {
        padding: 3px 8px
    }

    .kl>* {
        margin-right: 24px
    }

    .km> :last-child {
        margin-right: 0
    }

    .mc {
        margin: 0
    }

    .nd {
        margin-top: 56px
    }

    .oc {
        font-size: 20px
    }

    .od {
        margin-top: 2.14em
    }

    .oe {
        line-height: 32px
    }

    .of {
        letter-spacing: -0.003em
    }

    .pa {
        font-size: 24px
    }

    .pb {
        margin-top: 1.95em
    }

    .pc {
        line-height: 30px
    }

    .pd {
        letter-spacing: -0.016em
    }

    .pm {
        margin-top: 0.94em
    }

    .pu {
        margin-top: 1.14em
    }

    .qg {
        margin-top: 1.72em
    }

    .qh {
        line-height: 24px
    }

    .qi {
        letter-spacing: 0
    }

    .qz {
        margin-top: 1.25em
    }

    .ro {
        margin-bottom: 88px
    }

    .sf {
        padding-top: 72px
    }

    .tk {
        margin-top: 40px
    }
}

@media screen and (min-width:728px) and (max-width:903.98px) {
    .j {
        display: none
    }

    .w {
        display: flex
    }

    .x {
        justify-content: space-between
    }

    .bt {
        width: 64px
    }

    .cd {
        margin: 0 48px
    }

    .cs {
        height: 48px
    }

    .cz {
        margin-bottom: 52px
    }

    .dl {
        margin-bottom: 48px
    }

    .ea {
        font-size: 13px
    }

    .eb {
        line-height: 20px
    }

    .ei {
        padding: 0px 8px 1px
    }

    .fv {
        margin-bottom: 68px
    }

    .fz {
        max-width: 680px
    }

    .hd {
        font-size: 42px
    }

    .he {
        margin-top: 1.19em
    }

    .hf {
        line-height: 52px
    }

    .hg {
        letter-spacing: -0.011em
    }

    .hx {
        font-size: 22px
    }

    .hy {
        margin-top: 0.92em
    }

    .hz {
        line-height: 28px
    }

    .io {
        align-items: center
    }

    .jt {
        border-top: solid 1px #F2F2F2
    }

    .ju {
        border-bottom: solid 1px #F2F2F2
    }

    .jv {
        margin: 32px 0 0
    }

    .jw {
        padding: 3px 8px
    }

    .kj>* {
        margin-right: 24px
    }

    .kk> :last-child {
        margin-right: 0
    }

    .mb {
        margin: 0
    }

    .nc {
        margin-top: 56px
    }

    .ny {
        font-size: 20px
    }

    .nz {
        margin-top: 2.14em
    }

    .oa {
        line-height: 32px
    }

    .ob {
        letter-spacing: -0.003em
    }

    .ow {
        font-size: 24px
    }

    .ox {
        margin-top: 1.95em
    }

    .oy {
        line-height: 30px
    }

    .oz {
        letter-spacing: -0.016em
    }

    .pl {
        margin-top: 0.94em
    }

    .pt {
        margin-top: 1.14em
    }

    .qd {
        margin-top: 1.72em
    }

    .qe {
        line-height: 24px
    }

    .qf {
        letter-spacing: 0
    }

    .qy {
        margin-top: 1.25em
    }

    .rn {
        margin-bottom: 88px
    }

    .se {
        padding-top: 72px
    }

    .tj {
        margin-top: 40px
    }
}

/* </style> */
/* <style type="text/css" data-fela-rehydration="535" data-fela-type="RULE"
		media="all and (min-width: 552px) and (max-width: 727.98px)"> */

/* (min-width: 552px) and (max-width: 727.98px) */
@media screen and (min-width:552px) and (max-width:727.98px) {

    .k {
        display: none
    }

    .u {
        display: flex
    }

    .v {
        justify-content: space-between
    }

    .bs {
        width: 24px
    }

    .cc {
        margin: 0 24px
    }

    .cr {
        height: 40px
    }

    .cy {
        margin-bottom: 44px
    }

    .dk {
        margin-bottom: 32px
    }

    .dy {
        font-size: 13px
    }

    .dz {
        line-height: 20px
    }

    .eh {
        padding: 0px 8px 1px
    }

    .fu {
        margin-bottom: 4px
    }

    .gz {
        font-size: 32px
    }

    .ha {
        margin-top: 1.01em
    }

    .hb {
        line-height: 38px
    }

    .hc {
        letter-spacing: -0.014em
    }

    .hu {
        font-size: 18px
    }

    .hv {
        margin-top: 0.79em
    }

    .hw {
        line-height: 24px
    }

    .in {
        align-items: flex-start
    }

    .jl {
        flex-direction: column
    }

    .jr {
        margin: 24px 0 0
    }

    .js {
        padding: 0
    }

    .kh>* {
        margin-right: 8px
    }

    .ki> :last-child {
        margin-right: 8px
    }

    .ky {
        margin-left: 0px
    }

    .ma {
        margin: 0
    }

    .mq {
        border: 1px solid #F2F2F2
    }

    .mr {
        border-radius: 99em
    }

    .ms {
        padding: 0px 16px 0px 12px
    }

    .mt {
        height: 38px
    }

    .mu {
        align-items: center
    }

    .mw svg {
        margin-right: 8px
    }

    .nb {
        margin-top: 40px
    }

    .nv {
        margin-top: 1.56em
    }

    .nw {
        line-height: 28px
    }

    .nx {
        letter-spacing: -0.003em
    }

    .ot {
        font-size: 20px
    }

    .ou {
        margin-top: 1.2em
    }

    .ov {
        letter-spacing: 0
    }

    .pk {
        margin-top: 0.67em
    }

    .ps {
        margin-top: 1.34em
    }

    .qb {
        font-size: 16px
    }

    .qc {
        margin-top: 1.23em
    }

    .qx {
        margin-top: 0.93em
    }

    .rm {
        margin-bottom: 80px
    }

    .sd {
        padding-top: 48px
    }

    .ti {
        margin-top: 32px
    }

    .mv:hover {
        border-color: #E5E5E5
    }
}

@media print {
    .rv {
        display: none
    }
}

@media (prefers-reduced-motion: no-preference) {
    .ng {
        transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1)
    }
}






/* @media (min-width: 768px) {
    html {
        font-size: 125%;
        max-width: 42em;
 } } */

.blog-contents h1, .blog-contents h2,.blog-contents h3, .blog-contents h4 {
    margin: 2.5rem 0 1.5rem 0;
    line-height: 1.25;
    color: #ffffff;
 }
.blog-contents ul{
    list-style:inherit !important; 
}
.blog-contents a {
    color: #3296fa;
    text-decoration: none;
 }
.blog-contents a:hover, a:focus, a:active {
    text-decoration: underline;
 }

.blog-contents p {
    margin: 1em 0;
    line-height: 1.5;
 }
.blog-contents p .blog-contents code {
    background-color: #eee;
    padding: 0.05em 0.2em;
    border: 1px solid #ccc;
 }

.blog-contents ol, .blog-contents ul {
    margin: 1em;
 }
.blog-contents ol .blog-contents li .blog-contents ol, .blog-contents ol .blog-contents li .blog-contents ul, .blog-contents ul .blog-contents li .blog-contents ol, .blog-contents ul .blog-contents li .blog-contents ul {
    margin: 0 2em;
 }
.blog-contents ol .blog-contents li .blog-contents p, .blog-contents ul .blog-contents li .blog-contents p {
    margin: 0;
 }

.blog-contents dl {
    font-family: monospace, monospace;
 }
.blog-contents dl .blog-contents dt {
    font-weight: bold;
 }
dl dd {
    margin: -1em 0 1em 1em;
 }

.blog-contents img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0.5em;
 }

.blog-contents blockquote {
    padding-left: 1em;
    font-style: italic;
    border-left: solid 1px #fa6432;
 }

.blog-contents table {
    font-size: 1rem;
    text-align: left;
    caption-side: bottom;
    margin-bottom: 2em;
 }
.blog-contents table * {
    border: none;
 }
.blog-contents table thead, .blog-contents table .blog-contents tr {
    display: table;
    table-layout: fixed;
    width: 100%;
 }
.blog-contents table tr:nth-child(even) {
    background-color: rgba(200, 200, 200, 0.2);
 }
.blog-contents table .blog-contents tbody {
    display: block;
    max-height: 70vh;
    overflow-y: auto;
 }
.blog-contents table .blog-contents td, .blog-contents table .blog-contents th {
    padding: 0.25em;
 }

.blog-contents table, .highlight > .blog-contents pre, .blog-contents pre.example {
    max-height: 70vh;
    margin: 1em 0;
    padding: 1em;
    overflow: auto;
    font-size: 0.85rem;
    font-family: monospace, monospace;
    border: 1px dashed rgba(250, 100, 50, 0.5);
}

.blog-contents .title {
    font-size: 2.5em;
}

.blog-contents .subtitle {
    font-weight: normal;
    font-size: 0.75em;
    color: #666;
}

.blog-contents .tags {
    margin-top: -1.5rem;
    padding-bottom: 1.5em;
}
.blog-contents .tags li {
    display: inline;
    margin-right: 0.5em;
}

.blog-contents figure {
    margin: 1em 0;
}
.blog-contents figure .blog-contents figcaption {
    font-family: monospace, monospace;
    font-size: 0.75em;
    text-align: center;
    color: grey;
}

.footnote-definition sup {
    margin-left: -1.5em;
    float: left;
}.blog-contents 

.blog-contents .footnote-definition .blog-contents .footnote-body {
    margin: 1em 0;
    padding: 0 1em;
    border: 1px dashed rgba(250, 100, 50, 0.3);
    background-color: rgba(200, 200, 200, 0.2);
}
.blog-contents .footnote-definition .blog-contents .footnote-body p:only-child {
    margin: 0.2em 0;
}

.blog-contents header {
    display: flex;
    justify-content: space-between;
}
.blog-contents header .blog-contents nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog-contents header .blog-contents a + a {
    margin-left: 1rem;
}

/* .blog-contents {
    margin: 0;
    list-style: none;
} */
.blog-contents .blog-contents a {
    display: flex;
    padding: 0.5em 0;
    color: black;
}
.blog-contents .blog-contents a:hover, .blog-contents .blog-contents a:focus, .blog-contents .blog-contents a:active {
    text-decoration: none;
    background: rgba(200, 200, 200, 0.2);
}
.blog-contents .blog-contents date {
    font-family: monospace, monospace;
    font-size: 0.8rem;
    vertical-align: middle;
    padding-right: 2rem;
    color: grey;
}