.navbar-nav .nav-item .nav-link.active {
    border-bottom: 2px solid white;
    /* Add a white border-bottom to the active link */
}

.showHeadline {
    margin-top: 23px;
}

@media (max-width:266px) {
    .showHeadline {
        margin-top: 78px;
    }
}

@media (min-width:267px) and (max-width:373px) {
    .showHeadline {
        margin-top: 60px;
    }
}

@media (min-width:374px) and (max-width:740px) {
    .showHeadline {
        margin-top: 40px;
    }
}

.top-nav.navbar {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    transition: background-color 0.4s ease-in-out;
}

/* for set bg in mobile device */
@media (max-width: 991px) {
    .top-nav.navbar {
        background-color: #2e2e3c !important;
    }
}

.top-nav.navbar.scrolled {
    background-color: #2e2e3c !important;
}

.navbar-nav .dropdown-menu {
    position: static;
    background-color: black;
    border: none;
}

.navbar-nav .nav-link {
    color: white;
}