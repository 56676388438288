* {
  font-family: "Noto Sans", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

p.big{
  font-size: 1.2rem;
}

.noto-font {
  font-family: "Noto Sans", Helvetica, sans-serif;
}

.fw-thin {
  font-weight: 100 !important;
}

.section-next-link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
  border: 1px solid rgba(112, 112, 112, 0.3215686275);
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section-next-link:hover {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
}

.section-next-link i {
  padding-left: 10px;
}

/* .bg-black p,
.bg-black a {
    color: var(--bs-white);
} */
.bg-light p,
.bg-light a,
.text-bg-light p {
  /* color: var(--bs-gray-800) !important; */
}

.bg-design-br {
  background-image: url(/public/images/bg-lines-br.png);
  background-size: cover;
  background-position: bottom;
}

.end-5 {
  right: 5px !important;
}

.end-10 {
  right: 10px !important;
}

.end-15 {
  right: 15px !important;
}

.end-20 {
  right: 20px !important;
}

.end-25 {
  right: 25px !important;
}

.end-30 {
  right: 30px !important;
}

.top-5 {
  top: 5px !important;
}

.top-10 {
  top: 10px !important;
}

.top-15 {
  top: 15px !important;
}

.top-20 {
  top: 20px !important;
}

.top-25 {
  top: 25px !important;
}

.top-30 {
  top: 30px !important;
}

.lh-6 {
  line-height: 1.6 !important;
}

.b-divider-6 {
  width: 100%;
  height: 6rem;
}

video {
  max-height: 20rem;
  max-width: 99%;
}

.b-divider-20 {
  width: 100%;
  height: 20rem;
}

.b-divider-10 {
  width: 100%;
  height: 10rem;
}

.lh-6 {
  line-height: 1.6 !important;
}

.b-divider-6 {
  width: 100%;
  height: 6rem;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-indigo {
  background-color: #6610f2;
}

.bg-teal {
  background-color: #13795b;
}

.bg-cyan {
  background-color: #087990;
}

.bg-dark-blue {
  background-color: #052c65;
}

.bg-dark-blue-acent {
  background-color: #2e2e3c !important;
}

.bg-black-blue {
  background-color: #031633;
}

.bg-dark-indigo {
  background-color: #290661;
}

.bg-dark-cyan {
  background-color: #055160;
}

.bg-black-cyan {
  background-color: #032830;
}

.b-divider-20 {
  width: 100%;
  height: 20rem;
}

.b-divider-10 {
  width: 100%;
  height: 10rem;
}

.lh-6 {
  line-height: 1.6 !important;
}

.b-divider-6 {
  width: 100%;
  height: 6rem;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-indigo {
  background-color: #6610f2;
}

.bg-teal {
  background-color: #13795b;
}

.bg-cyan {
  background-color: #087990;
}

.bg-dark-blue {
  background-color: #181821;
}

.bg-black-blue {
  background-color: #031633;
}

.bg-dark-indigo {
  background-color: #290661;
}

.bg-dark-cyan {
  background-color: #055160;
}

.bg-black-cyan {
  background-color: #032830;
}

.gradient-text-light-blue {
  /* background: -webkit-linear-gradient(var(--bs-primary-border-subtle), var(--bs-info-border-subtle)); */
  background: linear-gradient(to right, var(--bs-primary-border-subtle), var(--bs-info-border-subtle));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.gradient-text-light-orange {
  background: linear-gradient(to right, var(--bs-orange), var(--bs-yellow));
  /* background: -webkit-linear-gradient(var(--bs-orange), var(--bs-yellow)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.gradient-text-light-green {
  background: linear-gradient(to right, var(--bs-green), var(--bs-teal));
  /* background: -webkit-linear-gradient(var(--bs-orange), var(--bs-yellow)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.gradient-text-light-pink {
  background: linear-gradient(to right, var(--bs-pink), var(--bs-red));
  /* background: -webkit-linear-gradient(var(--bs-orange), var(--bs-yellow)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.font-ubuntu {
  font-family: "Ubuntu", "Noto Sans", Helvetica, sans-serif;
}

.fs-7 {
  font-size: 0.75rem !important;
}

.fs-7-1 {
  font-size: 0.7rem !important;
}

.fs-7-2 {
  font-size: 0.65rem !important;
}

.fs-7-3 {
  font-size: 0.6rem !important;
}

.fs-7-4 {
  font-size: 0.55rem !important;
}

.fs-8 {
  font-size: 0.5rem !important;
}

.fs-9 {
  font-size: 0.25rem !important;
}

.tick {
  display: flex;
}

.tick input[type=checkbox] {
  margin-right: 8px;
}

.grid:after {
  content: "";
  display: block;
  clear: both;
}

.grid-sizer,
.grid-item {
  width: 33.333%;
}

@media (max-width: 575px) {

  .grid-sizer,
  .grid-item {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .grid-sizer,
  .grid-item {
    width: 50%;
  }
}

/* To change the amount of columns on larger devices, uncomment the code below */
/* @media (min-width: 768px) and (max-width: 991px) {
  .grid-sizer,
  .grid-item {
    width: 33.333%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .grid-sizer,
  .grid-item {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .grid-sizer,
  .grid-item {
    width: 20%;
  }
} */
.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

/*

/* Scroll top button */
#button {
  display: inline-block;
  background-color: #0d6efd;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 30px;
  text-decoration: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s,
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#button::after {
  /* content: "\f077"; */
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}

#button:hover {
  cursor: pointer;
  background-color: #333;
}

#button:active {
  background-color: #555;
}

#button.show {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 500px) {

  /* .content {
    width: 43%;
  } */
  #button {
    margin: 30px;
  }
}