svg.svg-hexa {
  fill: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

svg.svg-hexa:hover {
  fill: var(--bs-primary) !important;
  cursor: pointer;
}

svg.svg-hexa:hover path {
  fill: var(--bs-primary) !important;
}

.svg-div {
  display: none;
  /* opacity: 0; */
  width: 80%;
  height: 100%;
  /* background-color: red; */
  overflow: hidden;
  text-align: center;
  align-items: center;
  margin: auto;
}

svg.svg-hexa:hover .svg-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle-icon {
  display: none;
  /* background-image: url(images/rec.png); */
  /* background-image: url(../../images/rec.png); */
  background-image: url(../../images/rec.png);
  background-size: contain;
  background-position: 50px;
  background-repeat: no-repeat;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  animation: direction 0.5s none;
  animation-fill-mode: forwards;
}

svg:hover .circle-icon {
  display: block;
}

@keyframes direction {
  0% {
    left: -100px;
    top: 0px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}

svg.svg-hexa path {
  overflow: hidden;
  position: relative;
}

.svg-div p,
.svg-div h5 {
  pointer-events: none !important;
}

.svg-div p {
  font-size: 1rem;
}

.drone {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transition: transform 400ms ease-out;
  will-change: transform;
  pointer-events: none;
}

.hexagon {
  overflow: hidden;
  visibility: hidden;
  transform: rotate(120deg);
  cursor: pointer;
}

.hexagon1 {
  width: 415px;
  height: 220px;
  margin: 14px 0 0 -96px;
  transform: rotate(30deg);
}

.hexagon-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: rotate(-60deg);
}

.hexagon-in2 {
  width: 54%;
  height: 113%;
  background-repeat: no-repeat;
  background-position: 50%;
  /* background-image: url(http://placekitten.com/240/240); */
  visibility: visible;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  margin-top: -3.5%;
  margin-left: 23%;
  transform: rotate(30deg);
}

.container-element {
  display: grid;
  grid: auto/repeat(6, 1fr);
  gap: 8px;
}

.grid-item {
  /* padding: 1rem; */
  font-size: 30px;
  text-align: center;
  grid-column: span 2;
  margin-top: -100px;
}

.container-element .grid-item:nth-child(5n),
.container-element .grid-item:nth-child(5n-1) {
  grid-column: span 3;
}

.container-element .grid-item:nth-child(5n) svg,
.container-element .grid-item:nth-child(5n-1) svg {
  max-width: 394.67px;
  position: relative;
}

.container-element .grid-item:nth-child(5n) svg {
  left: -110px;
}

.container-element .grid-item:nth-child(5n-1) svg {
  left: 110px;
}

.work-detail-page {
  font-family: "Noto Sans";
}

.softwares-tools {
  display: inline-flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  border-radius: 28px;
  background: var(--bg-dark, #212529);
}

.side-title {
  color: var(--bg-white, #FFF);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14.837px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

ul.v-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.v-grid li {
  position: relative;
}

ul.v-grid li:hover .popup-data {
  display: flex;
  z-index: 10;
}

ul.v-grid li img {
  width: 60.762px;
  height: 60.762px;
}

.popup-work {
  background: rgba(0, 0, 0, 0.45);
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.popup-data {
  display: none;
  width: 356px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 24.252px;
  background: var(--bg-white, #FFF);
  /* drop-up-shadow */
  box-shadow: 20px -20px 30px 15px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 80px;
  top: -20px;
}

.tool-title {
  color: var(--bg-black, #000);
  font-family: "Noto Sans";
  font-size: 13.473px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.tool-detail {
  color: var(--bg-black, #000);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.work-boxes {
  display: flex;
  padding: 22.231px 19.536px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16.168px;
  border-radius: 24.252px;
  background: var(--bg-dark, #212529);
  color: var(--bg-white, #FFF);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: 1.2rem;
}

.work-boxes .title {
  font-size: 16px;
  font-weight: 600;
}

.work-fit-boxes {
  align-items: flex-start;
  align-content: flex-start;
}

.hr-line {
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.09);
  width: 348px;
  height: 1px;
}

.work-boxes .detail li {
  margin-bottom: 12px;
}

.work-box-round {
  border-radius: 28px;
  background: var(--bg-dark, #212529);
  position: relative;
}

.work-process {
  padding: 0 !important;
  min-height: 1277px;
}

.road-image {
  /* background-image: url(../assets/images/work-detail/process/bg.png); */
  background-image: url(../../images/work-detail/process/bg.png);
  width: 100%;
  height: 100%;
  max-width: 1320px;
  max-height: 2380px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.work-box-title {
  color: var(--bg-white, #FFF);
  font-family: "Noto Sans";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
}

.work-box-title span {
  font-size: 2rem;
  font-weight: 100;
}

.progress-title {
  position: relative;
  top: 95px;
  left: 45px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.box-step {
  width: 200px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.planning {
  top: 12.2%;
  right: 0rem;
}

.design-phase {
  top: 18.5%;
  left: 25%;
  align-items: flex-end;
}

.development {
  top: 31.2%;
  left: 0%;
  align-items: flex-end;
}

.artwork {
  top: 40%;
  left: 43%;
}

.testing {
  top: 41%;
  right: 5%;
}

.deployment {
  top: 54%;
  left: 9.3%;
  align-items: flex-end;
}

.refinment {
  top: 60%;
  right: 5%;
}

.support {
  top: 79%;
  left: 33%;
}

.box-step p.title {
  color: var(--bg-white, #FFF);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.box-step p.detail {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 200;
  line-height: 0.8rem;
  text-transform: none;
  width: 140px;
}

.box-step p.detail span {
  color: var(--bg-white, #FFF);
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: block;
  margin-top: 1.2rem;
  margin-bottom: 0.3rem;
}

.temp {
  /* background-image: url(../assets/images/work-detail/features/key\ features.png); */
  background-image: url('../../images/work-detail/features/key features.png');
  width: 100%;
  height: 100%;
  max-width: 1320px;
  max-height: 2380px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 0;
}

.work-features {
  height: 807px;
}

.work-features .work-box-title {
  padding-left: 4rem;
}

.dotted-animated-path {
  stroke-dasharray: 8;
  animation: dash 4s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

#work-detail-design {
  position: relative;
  /* left: 50%; */
  /* -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.feature-box {
  z-index: 1;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-around;
}

.feature-box .hexa-feature-bg {
  background-image: url("../../images/work-detail/features/hexa-feature-bg.png");
  width: 33.25rem;
  height: 26rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  margin: 0 auto;
}

.feature-box .hexa-feature-bg .feature-box-text {
  position: relative;
  width: 100%;
  height: 100%;
}

.feature-box .hexa {
  background-image: url("../../images/work-detail/features/hexa-feature.png");
  width: 90px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 2;
}

.feature-box .hexa-art {
  left: 12px;
  top: 12px;
}

.feature-box .hexa-art .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-art.png");
}

.feature-box .hexa-exhibition {
  left: 116px;
  top: 12px;
}

.feature-box .hexa-exhibition .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-exhibition.png");
}

.feature-box .hexa-artist {
  left: 220px;
  top: 12px;
}

.feature-box .hexa-artist .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-artist.png");
}

.feature-box .hexa-mode {
  left: 65px;
  top: 103px;
}

.feature-box .hexa-mode .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-mode.png");
}

.feature-box .hexa-acquisition {
  left: 169px;
  top: 103px;
}

.feature-box .hexa-acquisition .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-acquisition.png");
}

.feature-box .hexa-interactive {
  left: 274px;
  top: 103px;
}

.feature-box .hexa-interactive .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-interactive.png");
}

.feature-box .hexa-settings {
  left: 377px;
  top: 105px;
}

.feature-box .hexa-settings .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-settings.png");
}

.feature-box .hexa-collaboration {
  left: 221px;
  top: 195px;
}

.feature-box .hexa-collaboration .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-collaboration.png");
}

.feature-box .hexa-metrics {
  left: 324px;
  top: 195px;
}

.feature-box .hexa-metrics .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-metrics.png");
}

.feature-box .hexa-promotion {
  left: 430px;
  top: 195px;
}

.feature-box .hexa-promotion .hexa-icon {
  background-image: url("../../images/work-detail/features/hexa-icon-promotion.png");
}

.feature-box .hexa-icon {
  display: flex;
  width: 50%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.work-feature-text-box {
  position: absolute;
  width: 170px;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;
}

.work-feature-text-box p.title {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  margin-bottom: 0.5rem;
}

.work-feature-text-box p.desc {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.text-art {
  top: 0rem;
  left: -13rem;
}

.text-exhibition {
  top: -7rem;
  left: 4rem;
}

.text-artist {
  top: -7rem;
  left: 18rem;
}

.text-mode {
  top: 10rem;
  left: -9rem;
}

.text-acquisition {
  top: 15rem;
  left: 1rem;
}

.text-interactive {
  top: 0rem;
  left: 22rem;
}

.text-settings {
  top: 6rem;
  left: 32rem;
}

.text-collaboration {
  top: 21rem;
  left: 14rem;
}

.text-metrics {
  top: 21rem;
  left: 27rem;
}

.text-promotion {
  top: 14rem;
  left: 36rem;
}

.work-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}

.work-results .result-icon {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 200px;
  margin: 3rem auto 0;
}

.work-results .result-box {
  width: 300px;
  height: 135px;
  margin-top: 2rem;
}

.work-results .result-point-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.work-results .number {
  background-image: url("../../images/work-detail/result/hexa-result-point.png");
  width: 100%;
  max-width: 60px;
  height: 60px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  line-height: 3.5rem;
  font-size: 2rem;
  margin-right: 1rem;
  background-position: center;
}

.work-results h4 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  width: 100%;
}

.work-results p {
  font-size: 12px;
  font-weight: 100;
}

.work-solutions h4 {
  font-size: 0.93rem;
  font-weight: 700;
  text-transform: uppercase !important;
  line-height: inherit;
  margin: 0;
}

.work-solutions p {
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: inherit;
  margin: 0;
}

.work-solutions .box-left h4,
.work-solutions .box-left p {
  text-align: right;
  margin: 0 1rem 0 2rem;
}

.work-solutions .box-right h4,
.work-solutions .box-right p {
  margin: 0 2rem 0 1rem;
}

.work-solutions .box-left,
.work-solutions .box-right,
.work-solutions .box-middle {
  padding: 5rem 0;
  position: relative;
}

.work-solutions .sol-8 .box-left,
.work-solutions .sol-8 .box-middle,
.work-solutions .sol-8 .box-right {
  padding-bottom: 5rem;
}

.work-solutions .sol-1 .box-left,
.work-solutions .sol-1 .box-middle,
.work-solutions .sol-1 .box-right {
  padding-top: 10rem;
}

.work-solutions .number {
  font-size: 7rem;
  font-weight: 700;
  position: absolute;
}

.work-solutions .sol-1 .number {
  left: -1.79rem;
  top: 4rem;
}

.work-solutions .sol-2 .number {
  left: 1rem;
  top: -3rem;
}

.work-solutions .sol-3 .number {
  right: -1.2rem;
  top: -1.3rem;
}

.work-solutions .sol-4 .number {
  right: 1.4rem;
  top: -3.9rem;
}

.work-solutions .sol-5 .number {
  right: -1rem;
  top: -1.5rem;
}

.work-solutions .sol-6 .number {
  left: -1.5rem;
  top: -2.2rem;
}

.work-solutions .sol-7 .number {
  right: -1rem;
  top: -1rem;
}

.work-solutions .sol-8 .number {
  left: -1rem;
  bottom: 0.8rem;
}

.work-solutions .heading {
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0 1rem 0 1rem;
}

.team-colo-photo {
  background-image: url("../../images/work-detail/team/hexa-team-colobrator.png");
  width: 10rem;
  height: 12rem;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  line-height: 3.5rem;
  font-size: 1rem;
  background-position: center;
  position: relative;
}

.team-colo-photo img {
  filter: gray;
  /* IE6-9 */
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}

.team-photos {
  display: flex;
  align-content: center;
  justify-content: center;
}

.team-photos .container-element {
  gap: 0;
  margin: 5rem 0;
}

.team-photos .container-element .grid-item {
  padding: 0;
  margin-top: -3.3rem;
}

.team-photos .container-element .grid-item:nth-child(5n-1) {
  margin-left: 5rem;
}

.team-photos .container-element .grid-item:nth-child(5n) {
  margin-left: 0rem;
}

.team-photos .team-colo-photo:hover .overlay-photo,
.team-photos .team-colo-photo:hover .buttons-social {
  display: flex;
}

.team-photos .team-colo-photo:hover .name {
  display: block;
}

.team-photos .clip-hexa {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 94% 75%, 50% 100%, 0 71%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 94% 75%, 50% 100%, 0 71%, 0 25%);
  width: 10rem;
  margin-top: 1.7rem;
}

.team-photos .overlay-photo {
  display: none;
  position: absolute;
  width: 100%;
  height: 96%;
  background-image: url(../../images/work-detail/team/hexa-team-colobrator-hover-2.png);
  z-index: 2;
  top: 4px;
  left: 0px;
  background-size: contain;
  opacity: 0.8;
}

.team-photos .name {
  display: none;
  position: absolute;
  width: 8rem;
  height: 1rem;
  transform: translate(-50%, -50%);
  top: 35%;
  left: 50%;
  z-index: 3;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
}

.team-photos .buttons-social {
  position: absolute;
  width: 5rem;
  height: 1rem;
  transform: translate(-50%, -50%);
  bottom: 35%;
  left: 50%;
  z-index: 3;
  font-size: 1.6rem;
  display: none;
  flex-direction: row;
  align-content: flex-end;
  justify-content: space-around;
}

.team-photos .buttons-social a {
  color: white;
}

.small-text {
  font-size: 10px;
  font-weight: 200;
}

.amazing-deals {
  margin-top: 120px;
}

.amazing-deals .item {
  border-radius: 23px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.amazing-deals .item .image {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  overflow: hidden;
  height: 100%;
}

.amazing-deals .item span.techno,
.amazing-deals .item span.year {
  color: #787878;
  font-size: 0.8rem;
  margin-bottom: 20px;
  display: block;
}

.amazing-deals .item .sub-title {
  color: white;
  font-weight: 100;
  font-size: 1rem;
  display: block;
}

.amazing-deals .item span.list {
  font-size: 0.8rem;
  font-weight: 500;
}

.amazing-deals .item h4 {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.amazing-deals .item i {
  color: #777;
  margin-right: 5px;
}

.amazing-deals .item .content {
  padding: 30px 0px;
}

.amazing-deals .item p {
  margin-top: 10px;
  margin-bottom: 0.8rem;
}

/* .amazing-deals .item a {
  margin-top: 0.5rem;
} */

.amazing-deals ul.page-numbers {
  text-align: center;
  margin-top: 30px;
}

.amazing-deals ul.page-numbers li {
  display: inline-block;
  margin: 0px 5px;
}

.amazing-deals ul.page-numbers li a {
  display: inline-block;
  width: 46px;
  height: 46px;
  border: 1px solid #787878;
  line-height: 44px;
  color: #787878;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-radius: 50%;
  transition: all 0.3s;
}

.amazing-deals ul.page-numbers li .active a,
.amazing-deals ul.page-numbers li a:hover {
  background-color: #787878;
  color: #fff;
}

.amazing-deals ul.page-numbers li :first-child a,
.amazing-deals ul.page-numbers li :last-child a {
  border: none;
}

.image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* max-height: 400px; */
}


/* Add some styles for the thumbnail container */
.t-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

/* Add some styles for the video overlay */
.v-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  /* Initially hide the video overlay */
}

/* Style the video player */
.v-overlay video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: fit-content;
  max-width: -webkit-fill-available;
}

.inquiry-send:hover {
  color: var(--bs-green)
}

.inquiry-close {}

.btn-list-explore,
.btn-list-inquiry {
  /* height: 40px; */
}

@media (min-width: 992px) {
  .work-process {
    min-height: 1700px;
  }
}

@media (min-width: 1200px) {
  .road-image {
    background-size: contain;
  }
}

@media screen and (max-width: 990px) {
  .amazing-deals .item {
    padding-right: 0;
  }

  .amazing-deals .item .image {
    border-top-left-radius: 23px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 23px;
  }

  .amazing-deals .item .content {
    margin: 0 30px;
  }
}

.work-filters {
  top: -1rem;
  z-index: 10;
  display: flex;
  left: 20%;
  margin: 2rem auto !important;
}

.work-filters.scrolled {
  display: flex;
  position: fixed;
  justify-content: center;
  left: unset;
  width: auto;
  margin-left: 15% !important;
  margin-right: 19% !important;
}

/* display none work-filter.scrolled in 1090px */
@media screen and (max-width: 1040px) {
  .work-filters.scrolled {
    display: none;
  }

}

@media (max-width: 768px) {
  .work-filters {
    display: none;
  }

  .work-filters.scrolled {
    display: none;
    position: fixed;
  }
}