.statements {
    min-height: 800px;
}


.team .card {
    position: relative;
    position: relative;
    width: 229px;
    height: 235px;
    border: none;
    background-color: #aaa;
    margin: 0 auto;
}

.team .card-body {
    padding: 0;
}

.team .card-body h5 {
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 19px;
}

.team .card-body p {
    position: absolute;
    top: 40px;
    left: 18px;
    font-size: 10px;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
}

.team .card .nav {
    position: absolute;
    flex-direction: column;
    font-size: 20px;
    left: 20px;
    top: 50px;
}

.team .card .nav .nav-link {
    padding: 0;
    color: #888;
    transition: none;
}

.team .card .card-img-top {
    position: relative;
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(1);
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1);
    /* Microsoft Edge and Firefox 35+ */
}

.team .card:hover .card-img-top {
    filter: none;
    /* IE6-9 */
    -webkit-filter: none;
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: none;
    /* Microsoft Edge and Firefox 35+ */
}

.team .card.backend-success-dark .card-img-top {
    width: 290px;
    left: -5px;
    top: -14px;
}

.team .card.backend-success-light .card-img-top {
    width: 190px;
    top: 45px;
    left: 40px;
}

.team .card.backend-warning .card-img-top {
    width: 260px;
    top: 30px;
    left: 30px;
}

.team .card.backend-warning-light .card-img-top {
    width: 174px;
    top: 61px;
    left: 27px;
}

.team .card.backend-error .card-img-top {
    width: 150px;
    top: 65px;
    left: 45px;
}

.team .card.backend-info .card-img-top {
    width: 200px;
    top: 60px;
    left: 35px;
}

.team .card.backend-primary .card-img-top {
    width: 150px;
    top: 64px;
    left: 40px;
}

.team .card.backend-secondary .card-img-top {
    width: 310px;
    top: 0px;
    left: 0px;
}

.team .card.backend-accent .card-img-top {
    width: 168px;
    top: 67px;
    left: 35px;
}

.team .card.backend-neutral .card-img-top {
    width: 500px;
    top: -30px;
    left: -80px;
}

.team .card.backend-highlight .card-img-top {
    width: 230px;
    top: 50px;
    left: 25px;
}

.team .card.backend-muted .card-img-top {
    width: 270px;
    top: 40px;
    left: 20px;
}

.team .card.backend-dark .card-img-top {
    width: 200px;
    top: 40px;
    left: 35px;
}

.team .card.backend-teal .card-img-top {
    width: 300px;
    top: 30px;
    left: -15px;
}

.team .card.backend-orange .card-img-top {
    width: 175px;
    top: 60px;
    left: 35px;
}

.team .card.backend-green .card-img-top {
    width: 360px;
    top: 40px;
    left: -25px;
}


.team .card.default-bg:hover {
    background-color: #05a077 !important;
}

.team .card.backend-success-dark:hover {
    /* Changed from .nikul */
    background-color: #118AB2 !important;
}

.team .card.backend-success-light:hover {
    /* Changed from .tejas */
    background-color: #05a077 !important;
}

.team .card.backend-warning:hover {
    /* Changed from .dhruv */
    background-color: #FB8500 !important;
}

.team .card.backend-warning-light:hover {
    /* Changed from .kuldip_k */
    background-color: #FFB703 !important;
}
.team .card.backend-pritesh .card-img-top {
    left: 35px;
    top: 46px;
    width: 170px;
}
.team .card.backend-style .card-img-top {
    width: 184px;
    top: 10px;
    left: 35px;
}
.team .card.backend-error:hover {
    /* Changed from .shruti */
    background-color: #EF476F !important;
}

.team .card.backend-info:hover {
    /* Changed from .rakesh */
    background-color: #8ECAE6 !important;
}

.team .card.backend-primary:hover {
    /* Changed from .riddhi */
    background-color: #9381FF !important;
    /* background-color: #023047; */
}

.team .card.backend-secondary:hover {
    /* Changed from .honey */
    background-color: #219EBC !important;
}

.team .card.backend-accent:hover {
    /* Changed from .siddharth */
    background-color: #A5BE00 !important;
}

.team .card.backend-neutral:hover {
    /* Changed from .urvish */
    background-color: #427AA1 !important;
}

.team .card.backend-highlight:hover {
    /* Changed from .meet */
    background-color: #ffd166 !important;
}

.team .card.backend-muted:hover {
    /* Changed from .malvik */
    background-color: #dab6fc !important;
}

.team .card.backend-dark:hover {
    /* Changed from .mehul */
    background-color: #b5179e !important;
}

.team .card.backend-teal:hover {
    /* Changed from .chirag */
    background-color: #34a0a4 !important;
}

.team .card.backend-orange:hover {
    /* Changed from .hemang */
    background-color: #fca311 !important;
}

.team .card.backend-green:hover {
    /* Changed from .kuldip_p */
    background-color: #76c893 !important;
}

.team .card:hover .card-img-top {
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.2) -1rem 1rem 10px);
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: drop-shadow(rgba(0, 0, 0, 0.2) -1rem 1rem 10px);
    /* Microsoft Edge and Firefox 35+ */
}




.team .card:hover .nav .nav-link,
.team .card:hover h5,
.team .card:hover p.text-muted {
    color: white !important;
}



.bg-black.statements p {
    color: rgba(255, 255, 255, 0.15);
}

.statements h1.title {
    color: white;
    font-family: "Noto Sans", "Roboto", "Ubuntu";
    font-weight: 100;
}

.statements h1.title strong {
    font-family: 'Ubuntu';
    font-weight: 600;
}


.bg-design-br.statements p span {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6rem;
    color: rgba(255, 255, 255, 0.1);
    line-height: 6rem;
}

@media (max-width: 768px) {
    .bg-design-br.statements p {
        left: 0;
        display: none;
    }
}

.statements .top-left {
    position: absolute;
    max-width: 400px;
    left: 10%;
    top: 24%;
}

.statements .top-right {
    position: absolute;
    max-width: 400px;
    right: 7%;
    top: 17%;
}

.statements .top-center {
    position: absolute;
    max-width: 400px;
    top: 11%;
    left: 55%;
    transform: translate(-50%, 50%);
}

.statements .bottom-left {
    position: absolute;
    max-width: 400px;
    top: 66%;
    left: 12%;
}

.statements .bottom-center {
    position: absolute;
    max-width: 400px;
    top: 66%;
    left: 55%;
    transform: translate(-50%, 50%);
}

.statements .bottom-right {
    position: absolute;
    max-width: 400px;
    top: 60%;
    right: 10%;
}